// For reporting errors to Sentry.
import * as Sentry from '@sentry/browser';

// report in non dev only
if (window && window.settings && window.settings.sentry) {
  const {email, id, organization, release} = window.settings.sentry;

  Sentry.init({
    dsn: 'https://73d387254e6541219c5066f59b597544@o35841.ingest.sentry.io/80161',
    release,
    integrations: [Sentry.browserTracingIntegration()],

    // 10% of transactions for performance monitoring.
    tracesSampleRate: 0.1,
  });

  // Attach errors to users.
  if (email && id && organization) {
    Sentry.setUser({
      email,
      id,
      organization,
    });
  }
}
